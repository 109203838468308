import axios from 'axios'
//这里引用了element的loading全屏加载
import { Loading, Message } from "element-ui";
axios.defaults.withCredentials = true
const service = axios.create({
  // baseURL: '/devapi',
  // timeout: 30000 // 设置请求超时时间
})
let loading = "";
// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // console.log(get.localStorage())
    // 在请求发送之前做一些处理
    if (!(config.headers['Content-Type'])) {
      loading = Loading.service({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(255,255,255,0.7)",
        customClass: "request-loading",
      });
      if (config.method == 'post' && config.type == 'upload') {
        config.headers['Content-Type'] = 'multipart/form-data'
      } else if (config.method == 'post' && config.type == 'download') {
        // 导出csv文件
        config.headers['Content-Type'] = 'application/json;charset=UTF-8'
        config.responseType = 'blob'
      } else if (config.method == 'post' && config.rzheader) {
        // 导出csv文件
        config.headers['Content-Type'] = 'application/json;charset=UTF-8'
        for (let key in config.data) {
          if (config.data[key] === '') {
            delete config.data[key]
          }
        }
        config.data = JSON.stringify(config.data)
        config.headers['appId'] = config.rzheader.applId
        config.headers['signature'] = config.rzheader.signature
        config.headers['nonce'] = config.rzheader.nonce
        config.headers['timestamp'] = config.rzheader.timestamp
      } else if (config.method == 'post') {
        // config.headers['Content-Type'] = 'multipart/form-data'
        config.headers['Content-Type'] = 'application/json;charset=UTF-8'
        for (let key in config.data) {
          if (config.data[key] === '') {
            delete config.data[key]
          }
        }
        config.data = JSON.stringify(config.data)
      } else {
        config.headers['Content-Type'] =
          'application/x-www-form-urlencoded;charset=UTF-8'
        config.data = JSON.stringify(config.data)
      }
    }

    return config
  },
  (error) => {
    loading.close();
    // 发送失败
    console.log(error)
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    loading.close();
    // dataAxios 是 axios 返回数据中的 data
    // loadingInstance.close();
    if (response.config.type == 'download') {
      return response
    } else {
      const dataAxios = response.data
      // 这个状态码是和后端约定的
      if (dataAxios.code == 500) {
        Message.closeAll();
        Message({
          message: dataAxios.msg,
          type: 'error'
        });
      } else if (dataAxios.code == 900) {
        Message.closeAll();
        Message({
          message: dataAxios.msg,
          type: 'error'
        });
        setTimeout(() => {
          window.location.href = window.location.origin
        }, 3000);
        localStorage.clear()
      }
      return dataAxios
    }

  },
  (error) => {
    loading.close();
    if (error.response.status == 401) {
      process.env.NODE_ENV == "development" ?
        window.location.href = 'http://192.168.100.14:888/' :
        window.location.href = window.location.origin + '/'

      localStorage.clear()
      // 过期清除cookie
      let keys = document.cookie.match(/[^ =;]+(?==)/g)
      if (keys) {
        for (let i = keys.length; i--;) {
          document.cookie = keys[i] + '=0;path=/;expires=' + new Date(0).toUTCString() // 清除当前域名下的,例如：m.ratingdog.cn
          document.cookie = keys[i] + '=0;path=/;domain=' + document.domain + ';expires=' + new Date(0).toUTCString() // 清除当前域名下的，例如 .m.ratingdog.cn
          document.cookie = keys[i] + '=0;path=/;domain=ratingdog.cn;expires=' + new Date(0).toUTCString() // 清除一级域名下的或指定的，例如 .ratingdog.cn
        }
      }
    }
    return Promise.reject(error)
  }
)
// document.cookie = "EMS_TOKEN=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2MjQ3NTI5ODc4NDIsImlkIjoxMTExMTExMTExfQ.T5kOkVmHrnCxM5V16e9V6es9egMBOjbHKIZbd1i7pWY"
export function apiUrl () {
  // return 'http://180.169.235.162:8085/appo2fapi' // 测试环境
  return 'https://www.o2c-scf.com/api' // 正式环境
}
export default service